import React from "react";
import moment from "moment";
import {
    ActionCell,
    TypeCell,
    FooterCell,
    HeaderCell,
    CurrencyCell,
    StatusCell,
    DifferenceCell,
    PercentageCell,
    NameCell,
    MTDCell
} from "../../Shared/Table";
import IBudgetApi from "../../../interfaces/Budgets/IBudgetApi";
import {
    getMTDSpends,
    getIdealDailyBudget,
    getDaysInPeriod,
    getDiffDetails,
    getColumnTotal,
    getColumnMeanAverage,
    getLastChange,
    getDiff,
    getManagerFullName,
    getPerformanceManagerFullName,
    getFacebookIdealSpend,
    getParentAccountName
} from "../../Shared/Table/TableUtils";
import TableActionButton from "../../Shared/Table/TableActionButton";
import PencilIcon from "../../Shared/Icons/PencilIcon";
import TrashIcon from "../../Shared/Icons/TrashIcon";
import InformationIcon from "../../Shared/Icons/InformationIcon";
import TableActionLink from "../../Shared/Table/TableActionLink";
import { Column } from "react-table";
import IClient from "../../../interfaces/IClient";
import GoogleIcon from "../../Shared/Icons/GoogleIcon";
import GoogleColorIcon from "../../Shared/Icons/GoogleColoredIcon";
import MicrosoftColorIcon from "../../Shared/Icons/MicrosoftColorIcon";
import FacebookColorIcon from "../../Shared/Icons/FacebookColorIcon";
import CarsDotComColorIcon from "../../Shared/Icons/CarsDotComColorIcon";
import KoddiIcon from "../../Shared/Icons/KoddiIcon";
import VideoIcon from "../../Shared/Icons/VideoIcon";
import DisplayIcon from "../../Shared/Icons/DisplayIcon";
import { IBudgetTableFooter } from "../../../hooks/budgets/useBudget";

/**
 * Of all of the columns, these must be sortable where present.
 *  - Client name
 *  - PFM first name/last name/email
 *  - Manager first name/last name/email
 *  - pace
 *  - difference
 *  - last execution (if this column is still accurate!)
 *  - Status
 *  - MTD Spend
 *  @see https://carscommerce.slack.com/archives/C0GNCBXHQ/p1718388345449469?thread_ts=1717708628.409419&cid=C0GNCBXHQ
 */

export type BudgetAction = (budgetId: number) => void;

export interface IBuildTableColumnsSignature {
    showClientName: boolean;
    pushBudget: BudgetAction;
    deleteBudget: BudgetAction;
    isReadOnly: boolean;
    showOptional: boolean;
    footer: IBudgetTableFooter;
}

interface IClientApi {
    client: {
        id: number;
        name: string;
    };
}

interface IFacebookAccount {
    facebookAccount: null | {
        clients: IClient[];
    };
    budget: null | number;
    spend: null | number;
}

interface IOriginalProps extends IClientApi, IFacebookAccount, IBudgetApi {
    pfm: Partial<IClient>;
}

interface IDifferenceCellProps {
    value: {
        type: string;
        title: string;
    };
}

interface ICellProps {
    original: IOriginalProps;
    value: number;
}
interface IFooterProps {
    data: {};
    column: {
        id: number;
    };
}

interface IDetails {
    title: string | number;
    type: string;
}

interface IColumn<D = IOriginalProps> extends Partial<Column<D>> {
    sortUsing?: string;
}

const buildActionColumn = (pushBudget: BudgetAction, deleteBudget: BudgetAction, isReadOnly: boolean): IColumn => {
    const Cell = (props: ICellProps) => {
        const serviceId =
            props.original.activation && props.original.activation.serviceId
                ? props.original.activation.serviceId
                : null;
        return props.original.deletedAt ? null : (
            <ActionCell
                serviceId={serviceId}
                isReadOnly={isReadOnly}
                deletedAt={props.original.deletedAt}
                budgetId={props.original.id}
                clientId={props.original.clientId}
                pushBudget={pushBudget}
                deleteBudget={deleteBudget}
                activation={props.original.activation}
            />
        );
    };
    return {
        id: "ActionColumn",
        Header: "Actions",
        sortable: false,
        width: 140,
        Cell
    };
};

const buildTypeColumn = (): IColumn => {
    const Cell = (props: ICellProps) =>
        !!props.original.activation ? (
            <TypeCell
                typeId={props.original.activation.typeId}
                statusId={props.original.statusId}
                isManagedManually={props.original.activation.isManagedManually}
                label={props.original.activation.label}
                tooltipText={props.original.activation.eventEndsAt || ""}
                tooltipLabel={"Ends At"}
            />
        ) : null;

    return {
        id: "TypeColumn",
        Header: "Type",
        accessor: "activation.typeId",
        width: 110,
        Cell,
        sortable: false
    };
};

const buildStatusColumn = (): IColumn => {
    const Cell = (props: ICellProps) => <StatusCell statusId={props.original.statusId} />;
    return {
        id: "StatusColumn",
        Header: "Status",
        accessor: "statusId",
        width: 125,
        sortable: false,
        Cell
    };
};

const buildClientNameColumn = (overrideOptions: IColumnOverride = {}): IColumn => {
    const Cell = (props: ICellProps) => (
        <NameCell clientId={props.original?.client?.id} clientName={props.original?.client?.name} />
    );

    return {
        Cell,
        width: 125,
        Header: "Client",
        sortUsing: "client.name",
        sortable: true,
        id: "ClientNameColumn",
        ...overrideOptions
    };
};

const buildClientServicesPodColumn = (): IColumn => {
    return {
        Header: "CS Pod",
        sortUsing: "client.client_services_pod",
        accessor: "client.clientServicesPod",
        sortable: false,
        width: 95,
        id: "CSPodColumn"
    };
};

const buildLabelColumn = (): IColumn => ({
    Header: <HeaderCell label="Label" tooltipText="Campaigns under this label in Google Ads share spend." />,
    accessor: "activation.label",
    sortable: false,
    sortUsing: "activation.label",
    id: "LabelColumn"
});

const buildMonthlySpendColumn = (footer: IBudgetTableFooter): IColumn => {
    const Header = <HeaderCell label="Monthly Spend" tooltipText="The label’s total budget allotted for the month." />;
    const Cell = (props: ICellProps) => <CurrencyCell value={props.value} />;
    const Footer = (props: IFooterProps) => <FooterCell value={footer?.budget ?? 0} />;

    return {
        Header,
        Cell,
        Footer,
        width: 140,
        sortable: false,
        accessor: "activation.targetSpend",
        id: "MonthlySpendColumn"
    };
};

const buildMTDSpendColumn = (footer: IBudgetTableFooter): IColumn => {
    const Header = (
        <HeaderCell label="MTD Spend" tooltipText="How much the budget has spent thru yesterday (excluding today)." />
    );
    const Cell = (props: ICellProps) => <MTDCell value={props.value} />;
    const Footer = (props: IFooterProps) => <FooterCell value={footer?.mtdSpends ?? 0} />;
    const accessor = (budget: IBudgetApi) => {
        return getMTDSpends(budget);
    };

    return {
        id: "MTDSpends",
        width: 120,
        Header,
        Cell,
        Footer,
        accessor,
        sortable: true,
        // not a field, but a specific way to sort
        sortUsing: "mtd_spends"
    };
};

const buildCurrentSpendsColumn = (footer: IBudgetTableFooter): IColumn => {
    const Header = (
        <HeaderCell
            label="Current Spends"
            tooltipText="How much the budget has spent up until now. This is current to the last execution date & time."
        />
    );
    const Cell = (props: ICellProps) => <CurrencyCell value={props.value} />;
    const Footer = (props: IFooterProps) => (
        <FooterCell value={(footer?.mtdSpends ?? 0) + (footer?.todaySpends ?? 0)} />
    );
    const accessor = (data: IBudgetApi) => (data.eventSpends ? data.eventSpends : data.monthSpends);
    return {
        id: "currentSpends",
        Header,
        Cell,
        Footer,
        sortable: false,
        accessor
    };
};

const buildIdealSpendsColumn = (footer: IBudgetTableFooter): IColumn => {
    const Header = (
        <HeaderCell
            label="Ideal Spends"
            tooltipText="How much the budget should have spent to date in order to spend fully."
        />
    );
    const Cell = (props: ICellProps) => <CurrencyCell value={props.value} />;
    const Footer = (props: IFooterProps) => <FooterCell value={footer?.idealSpends ?? 0} />;

    return {
        Header,
        Cell,
        Footer,
        width: 150,
        sortable: false,
        accessor: "idealSpends",
        id: "IdealSpendsColumn"
    };
};

const buildDifferenceColumn = (footer: IBudgetTableFooter): IColumn => {
    const Header = <HeaderCell label="Difference" tooltipText="The difference between Ideal Spend & MTD Spend." />;

    const accessor = (data: IBudgetApi) => {
        if (!data.activation) {
            return null;
        }
        const { idealSpends } = data;
        const { targetSpend, eventStartsAt, eventEndsAt } = data.activation;
        const days = getDaysInPeriod(eventStartsAt, eventEndsAt);
        const idealDailyBudget = getIdealDailyBudget(targetSpend, days);
        const mtdSpends = getMTDSpends(data);
        const diff = getDiff(idealSpends, mtdSpends);
        const diffDetails: IDetails = getDiffDetails(diff, idealDailyBudget);
        return diffDetails;
    };

    const Cell = (props: IDifferenceCellProps) =>
        !!props.value ? <DifferenceCell type={props.value.type} title={props.value.title} /> : null;
    const sortMethod = (a: { title: number }, b: { title: number }) => (Math.abs(a.title) > Math.abs(b.title) ? 1 : -1);

    const Footer = (props: IFooterProps) => {
        return <FooterCell value={footer?.difference ?? 0} />;
    };

    return {
        id: "diff",
        Header,
        sortUsing: "difference",
        sortable: true,
        Cell,
        Footer,
        sortMethod,
        accessor
    };
};

const buildYesterdaySpendsColumn = (footer: IBudgetTableFooter): IColumn => {
    const Header = <HeaderCell label="Yesterday Spends" tooltipText="How much the budget spent yesterday." />;
    const Cell = (props: ICellProps) => <CurrencyCell value={props.value} />;
    const Footer = (props: IFooterProps) => <FooterCell value={footer?.yesterdaySpends ?? 0} />;

    return {
        Header,
        Cell,
        Footer,
        sortUsing: "yesterday_spends",
        accessor: "yesterdaySpends",
        id: "YesterdaySpendsColumn",
        sortable: false
    };
};

const buildPaceColumn = (footer: IBudgetTableFooter): IColumn => {
    const Header = <HeaderCell label="Pace" tooltipText="The month to date spend / ideal spend." />;

    const accessor = (data: IBudgetApi) => {
        if (!data.activation || !data.idealSpends) {
            return null;
        }
        const { idealSpends, eventSpends, monthSpends } = data;
        const currentSpends = eventSpends || monthSpends;

        return currentSpends / idealSpends;
    };

    const Cell = (props: ICellProps) => <PercentageCell value={props.value} />;
    const Footer = (props: IFooterProps) => {
        const idealSpends = getColumnTotal(props.data, "IdealSpendsColumn");
        const currentSpends = getColumnTotal(props.data, "currentSpends");
        return <FooterCell value={(footer?.pace ?? 0) / 100} isPercent={true} />;
    };

    return {
        id: "PaceColumn",
        Header,
        Cell,
        sortable: true,
        sortUsing: "pace",
        Footer,
        accessor
    };
};

const buildFulfillmentColumn = (): IColumn => {
    const Header = <HeaderCell label="Fulfillment" tooltipText="Last month's % fulfilled." />;

    const accessor = (data: IBudgetApi) => {
        if (!data.activation || !data.previousMonthFulfilledIdealSpends) {
            return null;
        }

        const { previousMonthFulfilledActualSpends, previousMonthFulfilledIdealSpends } = data;
        return previousMonthFulfilledActualSpends / previousMonthFulfilledIdealSpends;
    };

    const Cell = (props: ICellProps) => <PercentageCell value={props.value} />;

    return {
        id: "FulfillmentColumn",
        sortable: false,
        Header,
        Cell,
        accessor
    };
};

const buildLastExecutionColumn = (): IColumn => {
    const Header = (
        <HeaderCell
            label="Last Execution"
            tooltipText="The last time Fuel pulled updated data from Google. This runs once every 24 hours."
        />
    );
    const Cell = (props: ICellProps) => {
        const value = props.value ? moment(props.value).format("lll") : "Has not executed!";

        return <span>{value}</span>;
    };
    return {
        Header,
        Cell,
        sortable: true,
        sortUsing: "last_update_action_executed_at",
        width: 150,
        accessor: "lastUpdateActionExecutedAt",
        id: "LastExecutionColumn"
    };
};

// value is derived directly from api as newDailyBudget
const buildCurrentDailyBudgetColumn = (): IColumn => ({
    id: "CurrentDailyBudgetColumn",
    Header: (
        <HeaderCell
            label="Current Daily Budget"
            tooltipText="The current sum of all campaign’s daily budgets under this label."
        />
    ),
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    sortable: false,
    Footer: (props: IFooterProps) => <FooterCell value={getColumnTotal(props.data, props.column.id)} />,
    accessor: "newDailyBudget"
});

const buildLastChangeColumn = (): IColumn => {
    const Header = (
        <HeaderCell
            label="Last Change"
            tooltipText="The total amount the daily budgets were adjusted at the time of the last execution."
        />
    );

    const Cell = (props: ICellProps) => <CurrencyCell value={props.value} />;
    const accessor = (data: IBudgetApi) => getLastChange(data.oldDailyBudget, data.newDailyBudget);
    return {
        id: "LastChangeColumn",
        Header,
        sortable: false,
        Cell,
        accessor
    };
};

const buildIdealDailyBudgetColumn = (): IColumn => {
    const Header = (
        <HeaderCell
            label="Ideal Daily Budget"
            tooltipText="The monthly spend divided by the total days in the month. This is ideally what the sum of all daily budgets would be in Google, if all campaigns were on track to spend fully & pacing equally."
        />
    );

    const Cell = (props: ICellProps) => <CurrencyCell value={props.value} />;

    const accessor = (data: IBudgetApi) => {
        if (!data.activation) {
            return null;
        }
        const days = getDaysInPeriod(data.activation.eventStartsAt, data.activation.eventEndsAt);
        return days && getIdealDailyBudget(data.activation.targetSpend, days);
    };
    const Footer = (props: IFooterProps) => <FooterCell value={getColumnTotal(props.data, props.column.id)} />;

    return {
        id: "IdealDailyBudgetColumn",
        Header,
        Cell,
        sortable: false,
        Footer,
        accessor
    };
};

const buildClientManagerColumn = (overrideOptions: IColumnOverride = {}): IColumn => ({
    id: "ManagerColumn",
    Header: (
        <HeaderCell label="DigAd Specialist" tooltipText="The DigAd Specialist responsible for managing the budget" />
    ),
    maxWidth: 200,
    sortable: true,
    sortUsing: "client.manager.first_name",
    accessor: (budget: IOriginalProps) => getManagerFullName(budget.client),
    ...overrideOptions
});

const buildPerformanceManagerColumn = (overrideOptions: IColumnOverride = {}): IColumn => ({
    id: "PerformanceManagerColumn",
    Header: <HeaderCell label="Performance Manager" tooltipText="User responsible for managing performance" />,
    accessor: (budget: IOriginalProps) => getPerformanceManagerFullName(budget.client),
    maxWidth: 200,
    ...overrideOptions
});
interface IColumnOverride extends IColumn {}
const buildParentAccountColumn = (extraOptions: IColumnOverride = {}): IColumn => ({
    id: "ParentAccountColumn",
    Header: <HeaderCell label="Parent Account" tooltipText="Client's Salesforce Parent Account" />,
    sortable: false,
    maxWidth: 200,
    accessor: (budget: IOriginalProps) => getParentAccountName(budget.client),
    ...extraOptions
});

export const buildBudgetColumns = (
    showClientName: boolean,
    pushBudget: BudgetAction,
    deleteBudget: BudgetAction,
    isReadOnly: boolean,
    showOptional: boolean,
    footer: IBudgetTableFooter
) => {
    const budgetColumns: IColumn[] = [
        buildActionColumn(pushBudget, deleteBudget, isReadOnly),
        buildTypeColumn(),
        buildStatusColumn(),
        buildClientManagerColumn(),
        ...(showOptional ? [buildPerformanceManagerColumn(), buildParentAccountColumn()] : []),
        ...(showClientName ? [buildClientNameColumn()] : []),
        buildClientServicesPodColumn(),
        buildMonthlySpendColumn(footer),
        buildLabelColumn(),
        buildMTDSpendColumn(footer),
        buildCurrentSpendsColumn(footer),
        buildIdealSpendsColumn(footer),
        buildDifferenceColumn(footer),
        buildYesterdaySpendsColumn(footer),
        ...(showOptional ? [buildPaceColumn(footer)] : []),
        buildLastExecutionColumn(),
        ...(showOptional
            ? [
                  buildCurrentDailyBudgetColumn(),
                  buildLastChangeColumn(),
                  buildIdealDailyBudgetColumn(),
                  buildFulfillmentColumn()
              ]
            : [])
        // the following are hidden by default
    ];
    return budgetColumns;
};

export const buildAllBudgetColumns = (
    showClientName: boolean,
    pushBudget: BudgetAction,
    deleteBudget: BudgetAction,
    isReadOnly: boolean,
    showOptional: boolean,
    footer: IBudgetTableFooter
) => [
    {
        id: "type",
        accessor({ type }: { type: string }) {
            switch (type) {
                case "google":
                    return "Google";
                case "microsoft":
                    return "Microsoft";
                case "facebook":
                    return "Facebook";
                case "cars":
                    return "Cars Premium Display";
                case "koddi":
                    return "Inventory Search Ads";
                case "programmatic-video":
                    return "Programmatic Video";
                case "programmatic-display":
                    return "Programmatic Display";
                default:
                    return type;
            }
        },
        sortable: false,
        Header: "Type",
        width: 150
    },
    {
        id: "typeIcon",
        accessor({ type }: { type: string }) {
            switch (type) {
                case "google":
                    return <GoogleColorIcon className="w-4 h-4" />;
                case "microsoft":
                    return <MicrosoftColorIcon className="w-4 h-4" />;
                case "facebook":
                    return <FacebookColorIcon className="w-4 h-4" />;
                case "cars":
                    return <CarsDotComColorIcon className="w-4 h-4" />;
                case "koddi":
                    return <KoddiIcon className="w-4 h-4 text-blue-500" />;
                case "programmatic-video":
                    return <VideoIcon className="w-4 h-4" />;
                case "programmatic-display":
                    return <DisplayIcon className="w-4 h-4" />;
                default:
                    return type;
            }
        },
        Header: "",
        width: 50,
        sortable: false
    },
    {
        id: "budget",
        Cell: (props: ICellProps) => {
            if (props.value === Infinity) {
                return null;
            }

            return <CurrencyCell value={props.value} />;
        },
        accessor(budgetSummary: any) {
            if (!budgetSummary.budget) {
                return null;
            }

            return budgetSummary.budget;
        },
        Footer: (props: IFooterProps) => <FooterCell value={getColumnTotal(props.data, "budget")} />,
        sortable: false,
        Header: "Budget"
    },
    {
        id: "mtdSpend",
        Cell: (props: ICellProps) => {
            /** @ts-ignore */
            if (props.original.platform === "programmatic_display") {
                return <div />;
            }
            return <CurrencyCell value={props.value} />;
        },
        accessor(budgetSummary: any) {
            if (!budgetSummary.mtdSpends) {
                return null;
            }

            return parseFloat(budgetSummary.mtdSpends).toLocaleString();
        },
        sortable: false,
        Footer: (props: IFooterProps) => <FooterCell value={getColumnTotal(props.data, "mtdSpend")} />,
        Header: "MTD Spend"
    },
    {
        id: "pace",
        Cell: (props: ICellProps) => {
            /** @ts-ignore */
            if (props.original.platform === "programmatic-display") {
                return <div />;
            }

            return <PercentageCell value={props.value} />;
        },
        accessor(budgetSummary: any) {
            if (!budgetSummary.mtdSpends) {
                return null;
            }

            return budgetSummary.mtdSpends / (budgetSummary.budget * (moment().date() / moment().daysInMonth()));
        },
        Header: "Pace",
        sortable: true,
        sortUsing: "pace"
    },
    ...(showClientName
        ? [
              buildClientNameColumn({
                  maxWidth: 450,
                  width: 250
              })
          ]
        : []),
    buildClientManagerColumn({ maxWidth: 450 }),
    buildPerformanceManagerColumn({ maxWidth: 450 }),
    buildParentAccountColumn({ maxWidth: 450 })
];

// value is derived directly from api as newDailyBudget
const buildManualBudgetActionColumn = (
    budgetType: string,
    deleteBudget: (clientId: number, budgetId: number) => void
): IColumn => ({
    id: "ActionColumn",
    Header: "Actions",
    sortable: false,
    width: 110,
    Cell: (props: ICellProps) => (
        <div>
            {!props.original.deletedAt && (
                <TableActionLink
                    className="text-gray-500"
                    to={`/client/${props.original.clientId}/budgets/${budgetType}/${props.original.id}/details`}
                    Icon={InformationIcon}
                />
            )}
            {!props.original.deletedAt && (
                <TableActionLink
                    className="text-gray-500"
                    to={`/client/${props.original.clientId}/budgets/${budgetType}/${props.original.id}/edit`}
                    Icon={PencilIcon}
                />
            )}
            {!props.original.deletedAt && (
                <TableActionButton
                    onClick={() => {
                        deleteBudget(props.original.clientId, props.original.id);
                    }}
                    Icon={TrashIcon}
                    className="text-gray-500 hover:text-red-600"
                    title="Delete"
                />
            )}
        </div>
    )
});

const buildManualLabelColumn = (): IColumn => ({
    Header: <HeaderCell label="Label" tooltipText="The label the campaigns should have." />,
    sortable: false,
    accessor: "lastActivation.label",
    id: "LabelColumn"
});

const buildManualLastUpdatedAtColumn = (): IColumn => ({
    Header: <HeaderCell label="Last set at" tooltipText="The last time someone updated the budget." />,
    Cell: (props: ICellProps) => <span>{moment(props.value).format("lll")}</span>,
    width: 150,
    sortable: false,
    accessor: "createdAt",
    id: "LastSetAt"
});

const buildManualMonthlySpendColumn = (): IColumn => ({
    Header: <HeaderCell label="Monthly Spend" tooltipText="The label’s total budget allotted for the month." />,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    width: 140,
    sortable: true,
    sortUsing: "lastActivation.target_spend",
    accessor: "lastActivation.targetSpend",
    id: "MonthlySpendColumn"
});

const buildManualLastDeletedAtColumn = (): IColumn => ({
    Header: <HeaderCell label="Deleted on" tooltipText="The date and time someone deleted the budget." />,
    Cell: (props: ICellProps) => <span>{moment(props.value).format("lll")}</span>,
    width: 150,
    sortable: false,
    accessor: "deletedAt",
    id: "DeletedAt"
});

export const buildManualBudgetColumns = (
    budgetType: string,
    clientId: number | undefined,
    deleteBudget: (clientId: number, budgetId: number) => void,
    showDeleted: boolean
) => {
    return [
        ...(!showDeleted ? [buildManualBudgetActionColumn(budgetType, deleteBudget)] : []),
        ...(!clientId ? [buildClientNameColumn()] : []),
        buildClientManagerColumn(),
        buildClientNameColumn(),
        buildPerformanceManagerColumn(),
        buildParentAccountColumn(),
        buildClientServicesPodColumn(),
        buildManualMonthlySpendColumn(),
        buildManualLabelColumn(),
        buildManualLastUpdatedAtColumn(),
        ...(showDeleted ? [buildManualLastDeletedAtColumn()] : [])
    ];
};

const buildProgrammaticVideoCampaignNameColumn = (): IColumn => ({
    id: "Campaign",
    Header: <HeaderCell label="Campaign" tooltipText="Client's Campaign" />,
    accessor: (budget: any) => budget.adGroup,
    sortable: true,
    sortUsing: "campaign_name"
});
const buildProgrammaticVideoBudgetColumn = (footer: IBudgetTableFooter): IColumn => ({
    id: "Budget",
    Header: <HeaderCell label="Budget" tooltipText="Client's Budget" />,
    accessor: (budget: any) => budget.adGroupBudget,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.budget ?? 0} />,
    sortable: true,
    sortUsing: "budget"
});
const buildProgrammaticVideoMtdSpendsColumn = (footer: IBudgetTableFooter): IColumn => ({
    id: "MTD Spends",
    Header: <HeaderCell label="MTD Spends" tooltipText="Client's Budget" />,
    accessor: (budget: any) => budget.mtdSpends,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.mtdSpends ?? 0} />,
    sortable: false
});
const buildProgrammaticVideoIdealSpendsColumn = (footer: IBudgetTableFooter): IColumn => ({
    id: "Ideal Spends",
    Header: <HeaderCell label="Ideal Spends" tooltipText="Client's Ideal Spends" />,
    accessor: (budget: any) => budget.idealSpends,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.idealSpends ?? 0} />,
    sortable: false
});
const buildProgrammaticVideoDifferenceColumn = (footer: IBudgetTableFooter): IColumn => ({
    id: "Difference",
    Header: <HeaderCell label="Difference" tooltipText="Client's Budget Difference" />,
    accessor: (budget: any) => budget.difference,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.difference ?? 0} />,
    sortable: true,
    sortUsing: "difference"
});
const buildProgrammaticVideoYesterdaySpendColumn = (footer: IBudgetTableFooter): IColumn => ({
    id: "Yesterday Spend",
    Header: <HeaderCell label="Yesterday Spend" tooltipText="Client's Yesterday Spend" />,
    accessor: (budget: any) => budget.yesterdaySpends,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.yesterdaySpends ?? 0} />,
    sortable: false
});
const buildProgrammaticVideoPaceColumn = (footer: IBudgetTableFooter): IColumn => ({
    id: "Pace",
    Header: <HeaderCell label="Pace" tooltipText="Client's Pace" />,
    accessor: (budget: any) => budget.pace,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.pace ?? 0} />,
    sortUsing: "pace",
    sortable: true
});

export const buildProgrammaticVideoBudgetColumns = (
    showClientColumn: boolean,
    handlePushButton: (budgetId: number) => void,
    handleDeleteButton: (budgetId: number) => void,
    isReadOnly: boolean,
    showManagers: boolean,
    footer: IBudgetTableFooter
) => {
    return [
        ...(showClientColumn ? [buildClientNameColumn()] : []),
        buildClientManagerColumn(),
        buildPerformanceManagerColumn(),
        buildParentAccountColumn(),
        buildClientServicesPodColumn(),
        buildProgrammaticVideoCampaignNameColumn(),
        buildProgrammaticVideoBudgetColumn(footer),
        buildProgrammaticVideoMtdSpendsColumn(footer),
        buildProgrammaticVideoIdealSpendsColumn(footer),
        buildProgrammaticVideoDifferenceColumn(footer),
        buildProgrammaticVideoYesterdaySpendColumn(footer),
        buildProgrammaticVideoPaceColumn(footer)
    ];
};
const buildCarsPaceColumn = (footer: IBudgetTableFooter): IColumn => {
    const Header = <HeaderCell label="Pace" tooltipText="The month to date spend / ideal spend." />;

    const accessor = (props: any) => {
        return <PercentageCell value={props.cost / props.idealSpends} />;
    };
    const Footer = (props: IFooterProps) => {
        return <FooterCell value={(footer?.pace ?? 0) / 100} isPercent={true} />;
    };

    return {
        id: "CarsPaceColumn",
        Header,
        Footer,
        accessor,
        sortable: true,
        sortUsing: "pace"
    };
};

const buildCarsYesterdaySpendsColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: <HeaderCell label="Yesterday Spends" tooltipText="" />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.yesterdaySpends ?? 0} />,
    accessor(budget) {
        // @ts-ignore
        return <div>{Math.round((budget.cost - budget.yesterdaySpends) * 10000) / 10000}</div>;
    },
    id: "YesterdaySpendsColumn",
    sortable: false
});

const buildCarsIdealSpendsColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: <HeaderCell label="Ideal Spends" tooltipText="" />,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.idealSpends ?? 0} />,
    accessor: "idealSpends",
    id: "CarsIdealSpendsColumn",
    sortable: false
});

const buildCarsDifferenceColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: <HeaderCell label="Difference" tooltipText="" />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.difference ?? 0} />,
    accessor: "difference",
    sortable: true,
    sortUsing: "difference",
    id: "CarsDifferenceColumn"
});

const buildCarsStatusColumn = (): IColumn => {
    return {
        Header: <HeaderCell label="Status" tooltipText="" />,
        Cell: (props: ICellProps) => {
            const currentTime = moment();
            const diff = currentTime.diff(moment(props.value), "h");
            return <StatusCell statusId={diff > 24 ? 11 : 1} />;
        },
        sortable: false,
        accessor: "loadedAt",
        id: "CarsStatusColumn"
    };
};

const buildCarsBudgetColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: "Budget",
    id: "CarsBudgetColumn",
    accessor: "budget",
    sortable: false,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.budget ?? 0} />
});

const buildCarsMTDSpendColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: "MTD Spend",
    id: "CarsMTDSpendColumn",
    accessor: "cost",
    sortable: false,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.mtdSpends ?? 0} />
});

export const buildCarsBudgetColumns = (
    showClientColumn: boolean,
    handlePushBudget: () => void,
    handleDeleteBudget: () => void,
    isReadOnly: boolean,
    showManagers: boolean,
    footer: IBudgetTableFooter
) => {
    return [
        buildCarsStatusColumn(),
        buildClientManagerColumn(),
        buildPerformanceManagerColumn(),
        buildClientNameColumn(),
        buildParentAccountColumn(),
        buildCarsBudgetColumn(footer),
        buildCarsMTDSpendColumn(footer),
        buildCarsIdealSpendsColumn(footer),
        buildCarsDifferenceColumn(footer),
        buildCarsYesterdaySpendsColumn(footer),
        buildCarsPaceColumn(footer)
    ];
};

const buildFacebookClientColumn = (): IColumn => {
    return {
        id: "FacebookClientColumn",
        Header: <HeaderCell label="Client" tooltipText="Client associated with the Facebook budget" />,
        accessor: (budget: IOriginalProps) => {
            const client = budget?.facebookAccount?.clients[0];
            return client ? client.name : null;
        },
        sortable: true,
        sortUsing: "facebookAccount.clients.name"
    };
};

const buildFacebookParentAccountColumn = (): IColumn => {
    return {
        id: "FacebookParentAccountColumn",
        Header: <HeaderCell label="Parent Account" tooltipText="Client's Salesforce Parent Account" />,
        accessor: (budget: IOriginalProps) => {
            const client = budget?.facebookAccount?.clients[0];
            return client ? getParentAccountName(client) : null;
        },
        sortable: false
    };
};

const buildFacebookClientManagerColumn = (): IColumn => {
    return {
        id: "FacebookClientManagerColumn",
        Header: (
            <HeaderCell
                label="DigAd Specialist"
                tooltipText="The DigAd Specialist responsible for managing the budget"
            />
        ),
        sortable: true,
        sortUsing: "facebookAccount.clients.manager.first_name",
        accessor: (budget: IOriginalProps) => {
            const client = budget?.facebookAccount?.clients[0];
            return client ? getManagerFullName(client) : null;
        }
    };
};

const buildFacebookPerformanceManagerColumn = (): IColumn => {
    return {
        id: "FacebookPerformanceManagerColumn",
        Header: <HeaderCell label="Performance Manager" tooltipText="User responsible for managing budget" />,
        sortable: true,
        sortUsing: "facebookAccount.clients.pfm.first_name",
        accessor: (budget: IOriginalProps) => {
            const client = budget?.facebookAccount?.clients[0];
            buildPerformanceManagerColumn();
            return client ? getPerformanceManagerFullName(client) : null;
        }
    };
};

const buildFacebookCampaignNameColumn = (): IColumn => ({
    Header: "Campaign",
    id: "CampaignNameColumn",
    sortable: false,
    accessor: "facebookCampaignName"
});

const buildFacebookClientServicesPodColumn = (): IColumn => {
    return {
        Header: "CS Pod",
        width: 95,
        id: "FacebookCSPodColumn",
        sortable: false,
        accessor: (budget: IOriginalProps) => {
            const client = budget?.facebookAccount?.clients[0];
            return client ? client.clientServicesPod : null;
        }
    };
};

const buildFacebookBudgetColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: "Budget",
    id: "FacebookBudgetColumn",
    accessor: "budget",
    sortable: false,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.budget ?? 0} />
});

const buildFacebookMTDSpendColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: "MTD Spend",
    id: "FacebookMTDSpendColumn",
    accessor: "spend",
    sortUsing: "mtd_spends",
    sortable: true,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.mtdSpends ?? 0} />
});

const buildFacebookIdealSpendsColumn = (footer: IBudgetTableFooter): IColumn => {
    return {
        Header: "Ideal Spend",
        id: "FacebookIdealSpendColumn",
        sortable: false,
        accessor: (facebookBudget: IOriginalProps) => {
            const budget = facebookBudget?.budget ?? null;
            return budget ? getFacebookIdealSpend(budget).toFixed(2) : null;
        },
        Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
        Footer: (props: IFooterProps) => <FooterCell value={footer?.idealSpends ?? 0} />
    };
};

const buildFacebookDifferenceColumn = (footer: IBudgetTableFooter): IColumn => {
    return {
        Header: "Difference",
        id: "FacebookDifferenceColumn",
        accessor: (facebookBudget: IOriginalProps) => {
            const budget = facebookBudget?.budget ?? null;
            const spend = facebookBudget?.spend ?? null;
            return spend && budget ? (spend - getFacebookIdealSpend(budget)).toFixed(2) : null;
        },
        Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
        Footer: (props: IFooterProps) => <FooterCell value={footer?.difference ?? 0} />,
        sortable: true,
        sortUsing: "difference"
    };
};
const buildFacebookPaceColumn = (footer: IBudgetTableFooter): IColumn => {
    return {
        Header: "Pace",
        id: "FacebookPaceColumn",
        accessor: (facebookBudget: IOriginalProps) => {
            const budget = facebookBudget?.budget ?? null;
            const spend = facebookBudget?.spend ?? null;
            return spend && budget ? (spend / getFacebookIdealSpend(budget)).toFixed(2) : null;
        },
        Cell: (props: ICellProps) => <PercentageCell value={props.value} />,
        Footer: (props: IFooterProps) => <FooterCell value={(footer.pace ?? 0) / 100} isPercent={true} />,
        sortable: true,
        sortUsing: "pace"
    };
};

export const buildFacebookBudgetColumns = (
    showClientName: boolean,
    pushBudget: BudgetAction,
    deleteBudget: BudgetAction,
    isReadOnly: boolean,
    showOptional: boolean,
    footer: IBudgetTableFooter
): IColumn[] => {
    return [
        buildFacebookClientManagerColumn(),
        buildFacebookPerformanceManagerColumn(),
        buildFacebookClientColumn(),
        buildFacebookClientServicesPodColumn(),
        buildFacebookParentAccountColumn(),
        buildFacebookBudgetColumn(footer),
        buildFacebookCampaignNameColumn(),
        buildFacebookMTDSpendColumn(footer),
        buildFacebookIdealSpendsColumn(footer),
        buildFacebookDifferenceColumn(footer),
        buildFacebookYesterdaySpendsColumn(),
        buildFacebookPaceColumn(footer)
    ];
};

const buildKoddiStatusColumn = (): IColumn => ({
    Header: "Status",
    id: "KoddiStatusColumn",
    accessor: "campaignStatus",
    width: 95,
    sortable: true,
    sortUsing: "campaign_status"
});

const buildKoddiClientPerformanceManagerColumn = (): IColumn => ({
    id: "KoddiPerformanceManagerColumn",
    Header: <HeaderCell label="Performance Manager" tooltipText="User responsible for managing budget" />,
    accessor: (b: IOriginalProps) => {
        // @ts-ignore
        return getPerformanceManagerFullName(b);
    },
    sortable: true,
    sortUsing: "client.pfm.first_name"
});

const buildKoddiClientNameColumn = (): IColumn => ({
    Header: "Name",
    id: "KoddiClientNameColumn",
    accessor: "clientName",
    sortable: true,
    sortUsing: "client.name"
});

const buildKoddiClientServicesPodColumn = (): IColumn => ({
    Header: "CS Pod",
    id: "KoddiPodColumn",
    accessor: "csPod",
    width: 95,
    sortable: false
});

const buildKoddiCampaignNameColumn = (): IColumn => ({
    Header: "Campaign",
    id: "CampaignNameColumn",
    accessor: "campaignName",
    sortable: false
});

const buildKoddiBudgetColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: "Budget",
    id: "BudgetColumn",
    accessor: "budget",
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.budget ?? 0} />,
    sortable: false
});

const buildKoddiMTDSpendsColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: "MTD Spends",
    id: "MTDSpendsColumn",
    accessor: "mtdSpend",
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.mtdSpends ?? 0} />,
    sortable: true,
    sortUsing: "mtd_spends"
});

const buildKoddiIdealSpendsColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: <HeaderCell label="Ideal Spends" tooltipText="Updated around midday. May be inaccurate until afternoon." />,
    id: "IdealSpendsColumn",
    accessor: "idealSpends",
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.idealSpends ?? 0} />,
    sortable: false
});

const buildKoddiDifferenceColumn = (footer: IBudgetTableFooter): IColumn => ({
    Header: <HeaderCell label="Difference" tooltipText="Updated around midday. May be inaccurate until afternoon." />,
    id: "DifferenceColumn",
    accessor: "difference",
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    Footer: (props: IFooterProps) => <FooterCell value={footer?.difference ?? 0} />,
    sortable: false
});

const buildKoddiYesterdaySpendsColumn = (): IColumn => ({
    Header: (
        <HeaderCell
            label="Yesterday Spends"
            tooltipText="Updated around midday. May reflect previous day's spends until afternoon."
        />
    ),
    id: "YesteredaySpendsColumn",
    accessor: "yesterdaySpend",
    sortable: false,
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />
});

const buildFacebookYesterdaySpendsColumn = (): IColumn => ({
    Header: (
        <HeaderCell
            label="Yesterday Spends"
            tooltipText="Updated around midday. May reflect previous day's spends until afternoon."
        />
    ),
    id: "YesteredaySpendsColumn",
    accessor: "yesterdaySpend",
    Cell: (props: ICellProps) => <CurrencyCell value={props.value} />,
    sortable: false
});

const buildKoddiPaceColumn = (): IColumn => ({
    Header: "Pace",
    id: "PaceColumn",
    accessor: "pace",
    sortable: false,
    Cell: (props: ICellProps) => <PercentageCell value={props.value} />,
    Footer: (props: IFooterProps) => (
        <FooterCell value={getColumnMeanAverage(props.data, "PaceColumn")} isPercent={true} />
    ),
    width: 95
});

export const buildKoddiBudgetColumns = (
    showClientName: boolean,
    pushBudget: BudgetAction,
    deleteBudget: BudgetAction,
    isReadOnly: boolean,
    showOptional: boolean,
    footer: IBudgetTableFooter
) => {
    return [
        buildKoddiStatusColumn(),
        buildClientManagerColumn(),
        buildPerformanceManagerColumn(),
        buildClientNameColumn(),
        buildClientServicesPodColumn(),
        buildParentAccountColumn(),
        buildKoddiBudgetColumn(footer),
        buildKoddiCampaignNameColumn(),
        buildKoddiMTDSpendsColumn(footer),
        buildKoddiIdealSpendsColumn(footer),
        buildKoddiDifferenceColumn(footer),
        buildKoddiYesterdaySpendsColumn(),
        buildKoddiPaceColumn()
    ];
};
