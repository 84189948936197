import React from "react";
import actions from "../../../../actions/dynamicCampaigns/etaTemplateBoilerplate/etaTemplateBoilerplateActions";
import { connect } from "react-redux";
import { IEtaTemplateBoilerplate } from "../../../../interfaces/DynamicCampaigns/IEtaTemplateBoilerplate";
import IAppState from "../../../../interfaces/IAppState";
import { selectEtaTemplateBoilerplateIsDeleting } from "../../../../reducers/dynamicCampaigns/etaTemplateBoilerplate";
import TrashIcon from "../../../Shared/Icons/TrashIcon";
import LoaderSpinner from "../../../Shared/Loaders/LoaderSpinner";
import TemplateBoilerplateDeleteModal from "./EtaTemplateBoilerplateDeleteModal";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import { isSupervisor } from "../../../../reducers/currentUser";

interface IProps {
    etaTemplateBoilerplate: IEtaTemplateBoilerplate;
    checked: boolean;
    onCheck: (event: { target: { name: any; checked: any } }) => void;
}

const EtaTemplateBoilerplateRow = ({
    etaTemplateBoilerplate,
    checked,
    onCheck,
    currentUser,
    deleting,
    remove
}: IProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps) => {
    return (
        <li className={`w-full flex justify-between items-center even:bg-gray-200 p-3 h-16`}>
            <input
                className={`w-10`}
                style={{ marginTop: 0 }}
                name={etaTemplateBoilerplate.id.toString()}
                type="checkbox"
                checked={checked}
                onChange={onCheck}
            />
            <div className={`flex-grow`}>{etaTemplateBoilerplate.name}</div>
            {isSupervisor(currentUser) && (
                <div>
                    {deleting ? (
                        <LoaderSpinner className={`text-gray-500 w-10 h-10 mr-6`} />
                    ) : (
                        <ModalToggle
                            modalRoot={"secondary-modal-root"}
                            style={{ width: "50%", height: "510px" }}
                            toggleButton={(open: () => void) => {
                                const handleOpen = () => {
                                    if (checked) {
                                        onCheck({
                                            target: { name: etaTemplateBoilerplate.id.toString(), checked: false }
                                        });
                                    }
                                    open();
                                };
                                return (
                                    <button onClick={() => handleOpen()}>
                                        <TrashIcon
                                            className={`text-gray-500 hover:text-gray-600 w-6 h-6 mr-8 cursor-pointer`}
                                        />
                                    </button>
                                );
                            }}
                            modalContent={(hide: () => void) => (
                                <TemplateBoilerplateDeleteModal
                                    etaTemplateBoilerplate={etaTemplateBoilerplate}
                                    remove={remove}
                                    hide={hide}
                                />
                            )}
                        />
                    )}
                </div>
            )}
        </li>
    );
};

const mapStateToProps = (state: IAppState, { etaTemplateBoilerplate }: IProps) => ({
    currentUser: state.currentUser,
    deleting: selectEtaTemplateBoilerplateIsDeleting(state, etaTemplateBoilerplate.id)
});

const mapDispatchToProps = {
    remove: (boilerplate: IEtaTemplateBoilerplate) => actions.destroy.request({ boilerplate })
};

export default connect(mapStateToProps, mapDispatchToProps)(EtaTemplateBoilerplateRow);
