import React, { Fragment, useState, useRef } from "react";
import BudgetConstants from "../../../constants/budgets/BudgetConstants";
import ChevronUpIcon from "../Icons/ChevronUpIcon";
import InformationIcon from "../Icons/InformationIcon";
import EditIcon from "../Icons/EditIcon";
import TableActionButton from "./TableActionButton";
import TableActionLink from "./TableActionLink";
import TrashIcon from "../Icons/TrashIcon";
import moment from "moment";
import IActivationApi from "../../../interfaces/Budgets/IActivationApi";
import HistoryIcon from "../Icons/HistoryIcon";
import TimeIcon from "../Icons/TimeIcon";
import Svg from "../Icons/Svg";
import HeaderCell from "./HeaderCell";
import { Tooltip } from "./index";
import BudgetProrateIcon from "../Icons/BudgetProrateIcon";

interface IProps {
    deletedAt: string | null;
    budgetId: number;
    clientId: number;
    serviceId: number | null;
    isReadOnly: boolean;
    pushBudget: (budgetId: number) => void;
    deleteBudget: (budgetId: number) => void;
    activation?: IActivationApi;
}

const ActionCell: React.FunctionComponent<IProps> = ({
    deletedAt,
    budgetId,
    pushBudget,
    deleteBudget,
    clientId,
    isReadOnly,
    serviceId,
    activation
}) => {
    const ref = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const toggleHover = () => setShowTooltip(!showTooltip);
    if (!serviceId) {
        return (
            <Fragment>
                <div className="cell-wrapper action-cell text-gray-500">
                    {!isReadOnly && (
                        <Fragment>
                            <TableActionButton onClick={() => pushBudget(budgetId)} Icon={ChevronUpIcon} />
                            <TableActionButton onClick={() => deleteBudget(budgetId)} Icon={TrashIcon} />
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
    const budgetType = serviceId === BudgetConstants.BUDGET_SERVICE_ID_ADWORDS ? "adwords" : "microsoft";
    const basePath = `/client/${clientId}/budgets/${budgetType}/${budgetId}`;

    let showProratedIcon = false;
    if (
        activation?.proratedStartDate &&
        // So long as it is the same month as the prorated start date, we want to show the icon
        moment(activation.proratedStartDate).format("YYYYMM") === moment().format("YYYYMM")
    ) {
        showProratedIcon = true;
    }
    const prorateDateInPast = activation?.proratedStartDate
        ? moment(activation?.proratedStartDate).isBefore(moment())
        : false;

    return (
        <Fragment>
            {!deletedAt && (
                <div className="cell-wrapper action-cell text-gray-500">
                    <TableActionLink to={`${basePath}/details`} Icon={InformationIcon} />
                    {showProratedIcon && activation?.proratedStartDate && (
                        <>
                            <div ref={ref} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                                <BudgetProrateIcon className={"w-6 h-6 text-blue-500 inline-block"} />
                            </div>
                            <Tooltip reference={ref} shouldShow={showTooltip}>
                                <div className="font-bold">This budget is prorated</div>
                                <p>
                                    This budget {prorateDateInPast ? "has been" : "will be"} prorated{" "}
                                    {prorateDateInPast ? "since" : "starting"} {activation?.proratedStartDate} this
                                    indicator will be cleared automatically at the start of the next month.
                                </p>
                            </Tooltip>
                        </>
                    )}

                    {!isReadOnly && (
                        <Fragment>
                            <TableActionLink to={`${basePath}/edit`} Icon={EditIcon} />

                            <TableActionButton onClick={() => pushBudget(budgetId)} Icon={ChevronUpIcon} />

                            <TableActionButton
                                onClick={() => deleteBudget(budgetId)}
                                Icon={TrashIcon}
                                className="hover:text-red-500"
                            />
                        </Fragment>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default ActionCell;
